<template>
    <div>
        <CmpLogin />
    </div>
</template>

<script>
import CmpLogin from '@/components/login/LogIn.vue'

export default {
    name: 'VLogIn',
    components: {
        CmpLogin
    }
}
</script>
