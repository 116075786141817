<template>
    <section>
       
    <div class="footer acomodarFooter bgColorPrimary p-3 colorWhite has-text-centered">
        <div class="columns mt-2">

            <div class="column has-text-left is-offset-1">
                <p class="fontWeigth700 letterSpacing05 mb-1" v-if="brand.name != ''">
                    <font-awesome-icon :icon="brand.icon" class="title is-6 colorSecondary"/>
                    {{brand.name}}
                </p>
                <p class="fontWeigth400 letterSpacing05 mb-2 subtitle is-6 colorWhite" v-if="brand.slogan != ''">{{brand.slogan}}</p>
                <p class="fontWeigth400 letterSpacing05 mb-2 subtitle is-6 colorWhite" v-if="brand.slogan != ''">{{brand.viewMision}}</p>
                <p class="fontWeigth400 letterSpacing05 mb-2 subtitle is-6 colorWhite" v-if="brand.slogan != ''">{{brand.whyTrust}}</p>
            </div>

            <div class="column has-text-left">
                <p class="mb-1 colorWhite fontWeigth700">
                    <font-awesome-icon class="colorSecondary" :icon="['fa', 'mouse-pointer']"  /> Redes sociales
                </p>
                <a :href="brand.urlFB" class=" is-inline-block" target="_blank">
                <img    
                    src="@/assets/img/iconos/facebook.png"
                    alt="Corralon central"
                    width="20px" 
                > 
                <span class="colorWhite"> {{brand.name}}</span>
                </a>
                <a :href="brand.urlIG" class="is-block" target="_blank" >
                <img
                    src="@/assets/img/iconos/instagram.png"
                    alt="Corralon central"
                    width="20px"
                    class="mt-1"
                > <span class="colorWhite">{{brand.name}}</span>
                </a>
                <a :href="brand.whatsappUrl" class="is-block" target="_blank" >
                <img
                    src="@/assets/img/whatsappblanco.png"
                    style="display:none"
                    alt="Corralon central"
                    width="20px"
                    class="mt-1"
                > <span class="colorWhite">{{brand.phone}}</span>
                </a>
            </div>

            <div class="column has-text-left">
                <p class="mb-1 colorWhite fontWeigth700">
                    <font-awesome-icon class="colorSecondary" :icon="['fa', 'align-justify']"  /> Otros
                </p>
                <router-link :to="{name: 'VContact'}">
                <span class="colorWhite is-block mt-1">• Contacto </span>
                </router-link>
                <router-link :to="{name: 'VPrivacy'}">
                <span class="colorWhite is-block mt-1">• Politicas de privacidad </span>
                </router-link>
                <font-awesome-icon class="colorWhite" :icon="['fa', 'mail-bulk']"  />
                <span class="colorWhite"> {{brand.email}}</span>
            </div>
            
        </div>

        <br>



    </div>

    </section>
</template>



<script>
import { mapActions, mapGetters, mapState } from "vuex";
    export default {

        name: 'CmpFooter',

        computed: {
            ...mapState('brand', ['brand'])
        },

    }
</script>

<style scoped>

    .footer {
        margin:0px!important;
        padding:0px!important;
        width:100%;
        background-color:grey;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }



    /* Padding */
    /* Padding */
    .p-0 {
        padding:0px!important;        
    }
    .p-05 {
        padding:5px!important;        
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }




</style>