<template>
    <section>
            
        <div class="container mb-10">

        <div>
        <div class="mt-12">
            <div class="columns is-centered">

                <div class="column is-4 notification p-6 cuadradoInicioSesion card-2">
                    
                    <!-- El input envía los datos ingresados a la Data de Vue Js y luego dentro del "submit prevent"
                    igualo esos datos a los argumentos Email y Password que le paso a la función ingresoUsuario --> 
                    <form @submit.prevent="loginUser({email:email, password:password})">

                            <p class="colorPrimario mb-1 has-text-centered  title is-5 mb-1 fontWeigth500"> Inicio de sesion </p>
                            <p class="colorPrimario mb-1 has-text-centered  title is-7 mb-2 fontWeigth500"> Solo para corralones </p>
                            <div class="bgColorPrimary barraSeparadora mb-2"></div>
                            <!-- Email -->
                            <b-field  label="Email"  :label-position="labelPosition">
                                <b-input type="email"
                                    placeholder="Ingresa un email"
                                    v-model="email"
                                    maxlength="100"
                                    :has-counter="false"
                                  >
                                </b-input>
                            </b-field>

                            <div class="mb-2 is-block"></div>
                       
                            <!-- Contraseña -->
                            <b-field label="Contraseña" :label-position="labelPosition">
                                <b-input type="password"
                                    placeholder="Ingrese su contraseña"
                                    v-model="password"
                                    maxlength="20"
                                    :has-counter="false"
                                >
                                </b-input>
                            </b-field>

                            <!-- Errores -->
                            <p class="subtitle is-7 mt-1 colorSoftRed"
                            v-if="error === 'auth/user-not-found'">
                            Contraseña o email incorrecto.
                            </p>
   
                               <!-- Errores -->
                            <p class="subtitle is-7 mt-1 colorSoftRed"
                            v-if="error === 'auth/wrong-password'">
                            Contraseña o email incorrecto.
                            </p>
   
  
                            <!-- Errores -->
                            <p class="subtitle is-7 mt-1 colorSoftRed"
                            v-if="error === 'auth/too-many-requests'">
                            Demsiadas pruebas, por favor espera.
                            </p>

                            <p class="subtitle is-7 mt-1 colorSoftRed"
                            v-if="error === 'auth/account-exists-with-different-credential'">
                            Ya existe cuentas con una cuenta, prueba ingresar con Google u Facebook.
                            </p>

                            <!-- Boton -->
                            <div class="column has-text-centered marginBoton p-0">
                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorPrimary has-text-centered colorWhite w100"
                                      value="Ingresar"
                                      ></b-button>
                            </div>
                                   
                    </form>
                                           <!-- Boton -->
                            <router-link :to="{name: 'VRegister'}">
                            <div class="column has-text-centered marginBoton p-0">
                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorSecondary has-text-centered colorWhite w100"
                                      value="Crear usuario"
                                      ></b-button>
                            </div>
                            </router-link>

                            <router-link :to="{name: 'VRecoverPassword'}">
                            <p class="subtitle is-7 has-text-centered mb-2 mt-2">¿Olvidaste tu contraseña?</p>
                            </router-link>


                             <!-- Boton Google -->
                            <div class="column has-text-centered  marginBoton p-0" v-if="sociaLnetwork === true">
                                     <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorSoftRed w100 has-text-centered colorWhite"
                                      value="Iniciar sesión con Google"
                                      @click="accountGoogle"
                                      ></b-button>
                            </div>

                            <!-- Boton Facebook -->
                            <div class="column has-text-centered p-0" v-if="sociaLnetwork === true">
                                     <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorSoftBlue w100 has-text-centered colorWhite"
                                      value="Iniciar sesión con Facebook"
                                      @click="accountFacebook"
                                      ></b-button>
                            </div>
                
                            
                </div>

              </div>         
            </div>
    
        </div>
        </div>
        <CmpFooter />
    </section>
</template>

<script>

import { mapActions, mapState, mapMutations, mapGetters } from "vuex"
import { required, minLength, email } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import CmpFooter from '@/components/footer/Footer.vue'
import router from "@/router"

// Importo los metodos del archivo firebase
import { firebase, auth, db } from "@/firebase"

export default {
    
    name: 'CmpLogin',

    data() {
        return {
            email:'',
            labelPosition: 'on-border',
            password: '',
            show: true,
            prevRoute: null,
            sociaLnetwork: false,
        }
    },

    components: {
        PulseLoader,
        CmpFooter
    },


    mounted() {   
            // La unica posibilidad es decir que si el usuario viene de una ruta diferente a Iniciarsesion
            if(this.fromRoute === 'VBudgetHistory' || 'VCorralon' ||'VUserProfile') {
                router.push({name: 'VCorralon'}).catch(err => {})
            };
            this.goTop();
    },
 

    methods: {
        ...mapActions('sessions',['loginUser','accountFacebook','accountGoogle','checkRegisterUserRS']),
        ...mapMutations('sessions',['setUser']),
    goTop() {
             window.scrollTo(0, 0);
        },
     },

     computed: {
         
        ...mapState('brand', ['brand']),
        ...mapState('sessions',['user']),
        ...mapState('generalFunctions',['error','loader', 'colorLoader']  ),
        ...mapGetters('sessions',['userExist']),

     }
}
</script>

<style scoped>


.cuadradoInicioSesion {
    border-radius: 10px!important;
}


.card-2 {
box-shadow: 0 0px 5px rgba(0,0,0,0.06), 0 1px 10px rgba(0,0,0,0.05);
}

.barraSeparadora {
    width:50px;
    height:3px;
    margin:auto;
    display:block;
}

/* Footer */
/* Footer */

.mb-10 {
    margin-bottom:190px!important;
}

.mt-12 {
    margin-top:150px!important;
}


    @media screen and (max-width: 1023px) {

        .mb-10 {
            margin-bottom:130px!important;
        }

        .mt-12 {
            margin-top:100px!important;
        }

    }

    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }
    
/* Loader */
/* Loader */

.loaderCentrado {
    display:block !important;
    margin:auto !important;
    width:60px !important;
}

.textoCargando {
       color:#ff7850!important;
       font-weight:600!important; 
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


/* Width */
/* Width */

.w100 {
    width:100%!important;
}

.maxW300 {
    max-width:300px!important;
}

.maxW200 {
    max-width:200px!important;
}

.maxW100 {
    max-width:100px!important;
}

.maxW50 {
    max-width:50px!important;
}



/* Bordes */
/* Bordes */

.borderPrimary {
    border-color:#8752aa!important;
}

.borderSecondary {
    border-color:#ff7850!important;
}

.borderTertiary {
    border-color:#ffc627!important;
}


/* Botoness */
/* Botones */


.button:hover, .button.is-hovered {
    border-color: transparent!important;
    color: #363636;
}

.marginBoton {
    margin-left:0px !important;
    margin-right: 0px !important;
    margin-top:0px !important;
    margin-bottom:10px !important;
}

/* Margins */
/* Margins */

.mt-0 {
    margin-top:0px!important;
}

.mt-1 {
    margin-top:10px!important;
}

.mb-1 {
    margin-bottom:10px!important;
}


.mt-2 {
    margin-top:20px!important;
}

.mb-2 {
    margin-bottom:20px!important;
}



/* Padding */
/* Padding */

.p-0 {
    padding:0px!important;
}

.p-1 {
    padding:20px!important;
}

.p-2 {
    padding:20px!important;
}

.p-3 {
    padding:30px!important;
}

.p-6 {
    padding:60px!important;
}


/* Colors bg */
/* Colors bg */

.bgColorPrimary {
    background-color:#8752aa!important;
}

.bgColorPrimarySoft {
    background-color:#6f3695!important;
}


.bgColorSecondary {
    background-color:#ff7850!important;
}

.bgColorTertiary {
    background-color:#ffc627!important;
}

.bgColorSoftRed {
    background-color:#f64444!important;
}

.bgColorSoftBlue {
    background-color:#3d40fd!important;
}

/* Input */
/* Input */

.control .help.counter {
    float: right;
    margin-left: 0.5em;
    display: none!important;
}

</style>